import React from "react"
import Footer from "../components/footer"
import Hero from "../components/hero"
import ContactForm from "../components/contact-form"
import Testimonials from "../components/testimonials"
import C2A from "../components/c2a"
import { Helmet } from "react-helmet"

export default () => {
  return (
   
    <div>
       <Helmet>
          <meta charSet="utf-8" />
          <title>MV Property Care - Clarion, Pa Property Care, Lawn Care, Landscaping, Snow Removal</title>
    </Helmet>
    <Hero/>
    <Testimonials/>
    <C2A/>
    <ContactForm/>
    <Footer/>
    </div>
 
    
  )
}
