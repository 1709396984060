import React from "react";


function ContactForm() {
  return (
  
<div id ="contactForm" class="bg-white py-4 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-4">
  <div class="relative max-w-4xl mx-auto">
    
    
    <div class="mt-2">
      <form data-netlify="true" class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <div>
          <label for="first_name" class="block text-sm font-medium text-gray-700">First name</label>
          <div class="mt-1">
            <input type="text" name="first_name" id="first_name" autocomplete="given-name" class="border-gray-300 py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  rounded-md"/>
          </div>
        </div>
        <div>
          <label for="last_name" class="block text-sm font-medium text-gray-700">Last name</label>
          <div class="mt-1">
            <input type="text" name="last_name" id="last_name" autocomplete="family-name" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"/>
          </div>
        </div>
       
        <div class="sm:col-span-2">
          <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
          <div class="mt-1">
            <input id="email" name="email" type="email" autocomplete="email" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"/>
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="phone_number" class="block text-sm font-medium text-gray-700">Phone Number</label>
          <div class="mt-1 relative rounded-md shadow-sm">
           
            <input type="text" name="phone_number" id="phone_number" autocomplete="tel" class="py-3 px-4 block w-full  focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" placeholder="(814) 555-1111"/>
          </div>
        </div>
        
        
        <div class="sm:col-span-2">
          <button type="submit" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-mvgreen hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Let's talk
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

  
    
    
  );
}

export default ContactForm;
