import React from "react";

function C2A() {
  return (
    
    <div id="c2a" class="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div class="relative mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
          <defs>
            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>
    
        <div class="relative">
          <h2 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Free estimate for any service. Call (814)-221-7210.
          </h2>
          <p class="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            Fill out the form below to recieve a free estimate for your property.  No obligations, no sales pressure.  We want to help you get the best service possible.
          </p>
        </div>
    
        
      </div>
    </div>
    
  );
}

export default C2A;
