import React, { useState }  from "react";
import { Link } from "gatsby"


import logoImage from "../images/mv-logo.png"
import heroImage from "../images/hero1.jpeg"


function Hero() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
<div class="relative bg-white overflow-hidden">
  <div class="h-screen max-w-full mx-auto">
    <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-5xl lg:w-full lg:pb-28 xl:pb-32">
      <svg class="hidden lg:block absolute right-0 inset-y-0 h-screen w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>
      

      <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
        <nav class="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
          <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div class="flex items-center justify-between w-full md:w-auto">
              <a href="#">
                <span class="sr-only">MV Property Care</span>
                <img class="w-20" src={logoImage} alt="MV Logo" />
              </a>
              <div class="-mr-2 flex items-center md:hidden">
                <button onClick={() => setIsMenuOpen(true)} type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
                  <span class="sr-only">Open main menu</span>
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          
          <div class="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
            <Link to="lawn-care" class="font-medium text-gray-500 hover:text-gray-900">Lawn Care</Link>
            <Link to="landscaping" class="font-medium text-gray-500 hover:text-gray-900">Landcaping</Link>
            <Link to="snow-removal" class="font-medium text-gray-500 hover:text-gray-900">Snow Removal</Link>
            <Link to="other-services" class="font-medium text-gray-500 hover:text-gray-900">Other Services</Link>
            <Link to="lawn-tips" class="font-medium text-gray-500 hover:text-gray-900">Lawn Tips</Link>
            <Link to="contact" class="font-medium text-mvgreen hover:text-indigo-500">Contact Us</Link>
          </div>
         
        </nav>
      </div>
      {isMenuOpen && (
      <div class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
        <div class="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="px-5 pt-4 flex items-center justify-between">
            <div>
              <img class="h-24 w-auto" src={logoImage} alt="MV Logo"/>
            </div>
            <div class="-mr-2">
              <button onClick={() => setIsMenuOpen(false)} type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span class="sr-only">Close main menu</span>
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
         
          <div class="pt-5 pb-6">
          <div class="px-2 space-y-1">
              <Link to="/lawn-care" class="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50">Lawn Care</Link>  
              <Link to="/landscaping" class="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50">Landcaping</Link>  
              <Link to="/snow-removal" class="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50">Snow Removal</Link>
              <Link to="/other-services" class="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50">Other Services</Link>
              <Link to="/lawn-tips" class="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50">Lawn Tips</Link>
              
            </div>
            <div class="mt-6 px-5">
              <Link to="/contact" class="block text-center w-full py-2 px-4 border border-transparent rounded-md shadow bg-mvteal text-white font-medium hover:bg-teal-600">Contact Us</Link>
            </div>
          </div>
           
         
        </div>
      </div>
 )}
      <main class="mt-10 mx-auto max-w-5xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div class="sm:text-center lg:text-left">
          <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span class="block xl:inline">Out there so you don’t have to be.</span>
            <span class="block text-mvgreen xl:inline"> Mowing, raking, trimming, plowing...</span>
          </h1>
          <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-4xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          MV Property Care provides lawn care and land maintenance services throughout Clarion County and the surrounding areas, including Clarion, Rimersburg, East Brady, New Bethlehem, Sligo, Knox, Parker, Emlenton, and Foxburg.
          </p>
          <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <a href="#c2a" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-mvgreen hover:bg-mvteal md:py-4 md:text-lg md:px-10">
                Free Estimate
              </a>
            </div>
            <div class="mt-3 sm:mt-0 sm:ml-3">
              <Link to="/lawn-care" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-mvgreen bg-gray-100 hover:bg-mvteal md:py-4 md:text-lg md:px-10">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
  <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 hidden md:block">
    <img class="h-screen w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={heroImage} alt=""/>
  </div>
</div>
  );
}

export default Hero;
